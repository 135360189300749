import React, { useCallback, useEffect, useRef, useState } from 'react'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import flatten from 'lodash/flatten'
import intersection from 'lodash/intersection'
import isNumber from 'lodash/isNumber'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import throttle from 'lodash/throttle'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import without from 'lodash/without'
import { Button, Spinner, Tooltip } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import CaseInfo from '../components/consultations/CaseInfo'
import ConfirmModal from '../components/consultations/ConfirmModal'
import ConsultTagging from '../components/consultations/ConsultTagging'
import FulfillConsultationForm from '../components/consultations/Form'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import PriceCalculator from '../utils/PriceCalculator'
import { Conditions_conditions } from '../hasura/graphQlQueries/types/Conditions'
import { Consultations_consultations } from '../hasura/graphQlQueries/types/Consultations'
import { QueryName } from '../hasura/queryNames'
import { RegionOfInterestParams } from '../components/consultations/RegionOfInterest'
import { consultation_addendums_insert_input, predictions_normalized_insert_input } from '../../types/globalTypes'
import { fetchUserAction, fetchVetStatusesAction, updateVetStatusAction, usersSelector, UsersState } from '../hasura/slices/users'
import { findConsultationMistakesPrompt, queryLLM } from '../services/llm'
import { getCompletedByEnterpriseId } from '../lib/overflowHelpers'
import { isXrayCase } from '../lib/modalityHelpers'
import { setNotificationAction, NotificationId } from '../hasura/slices/notifications'
import { usePrevious } from '../hooks/usePrevious'
import { useTotalConsultationsCount } from '../hooks/useTotalConsultationsCount'

import {
  filterConditions,
  medicalImageKeysForCase,
  noHumanPrediction,
  ohifStudyUrl,
  searchQueryParams,
  shootConfetti,
  trackHotjarEvent,
} from '../lib/helpers'

import {
  ConsultationsState,
  completeConsultationAction,
  consultationsSelector,
  fetchConsultationAction,
  fetchImagesAction,
  fetchRequestedConsultationsAction,
  fetchSpecialistPayments,
  insertConsultationAddendumAction,
  insertConsultationFollowupAction,
  updateConsultationAddendumAction,
  updateConsultationNotesAction,
} from '../hasura/slices/consultations'

const AUTOSAVE_SECONDS = 15

export default function Consultations() {
  const dispatch = useDispatch()

  const { accessToken, user, vetStatus, role }: UsersState = useSelector(usersSelector)

  const {
    conditions,
    consultations,
    currentConsultation,
    isQuerying,
    multiViewerManager,
    presignedCaseImageUrls,
    specialistPayments,
  }: ConsultationsState = useSelector(consultationsSelector)

  const [addendum, setAddendum] = useState<string | undefined>()
  const [addendumPrivateText, setAddendumPrivateText] = useState<string | undefined>()
  const [checked, setChecked] = useState<string[]>([])
  const [consultation, setConsultation] = useState<Consultations_consultations | undefined>(undefined)
  const [customFinding, setCustomFinding] = useState<string>('')
  const [customFindings, setCustomFindings] = useState<predictions_normalized_insert_input[]>([])
  const [displayRegionOfInterest, setDisplayRegionOfInterest] = useState(false)
  const [followupDays, setFollowupDays] = useState<number>(3)
  const [followupText, setFollowupText] = useState<string | undefined>()
  const [inQueue, setInQueue] = useState(false)
  const [isTagging, setIsTagging] = useState(false)
  const [lastAutosaved, setLastAutosaved] = useState<string | undefined>()
  const [mistakes, setMistakes] = React.useState<any[]>([])
  const [modal, setModal] = useState(false)
  const [notes, setNotes] = useState<string | undefined>()
  const [privateNotes, setPrivateNotes] = useState<string | undefined>()
  const [regionOfInterest, setRegionOfInterest] = useState<RegionOfInterestParams | undefined>()
  const [selectedConditions, setSelectedConditions] = useState<predictions_normalized_insert_input[]>([])
  const [startedAt, setStartedAt] = useState<moment.Moment | undefined>(undefined)
  const [submitTooltipOpen, setSubmitTooltipOpen] = useState(false)
  const [suggested, setSuggested] = useState<number[]>([])
  const [taggingConsultation, setTaggingConsultation] = useState<Consultations_consultations | undefined>(undefined)

  const previouslyWorkedOnConsult: any = usePrevious(consultation)

  const { amount, amountWithBonuses, applyStatPlusBonus } = PriceCalculator.receivingVetPayAmount(
    specialistPayments,
    consultation,
    vetStatus,
    user,
    checked
  )

  const submitDisabled = inQueue ? !notes || !checked.length : !addendum
  const idQueryParam = parseInt(searchQueryParams('i') || '', 10)
  const cameFromSlackStatChannel = searchQueryParams('s') === 'slack-stat'
  const cameFromClaimList = searchQueryParams('s') === 'claim-list'

  /*
    Autosave
  */

  const autosaveInterval = useRef<any | null>(null)
  const notesRef = useRef(notes)
  notesRef.current = notes
  const consultationRef = useRef(consultation)
  consultationRef.current = consultation
  const lastAutosavedRef = useRef(lastAutosaved)
  lastAutosavedRef.current = lastAutosaved

  const autosave = () => {
    if (
      !consultationRef.current ||
      !notesRef.current ||
      notesRef.current === consultationRef.current.receiving_vet_notes ||
      notesRef.current === lastAutosavedRef.current
    ) {
      return
    }

    setLastAutosaved(notesRef.current)
    dispatch(updateConsultationNotesAction(accessToken, consultationRef.current.id, notesRef.current))
  }

  useEffect(() => {
    // SHORT TERM HACK:
    // if user is Marina, don't autosave because of reported lagginess on her end
    // if Marina reaches out again about this, we know the issue is not with autosave
    if (!user || user.id === 'auth0|61aa41074e04f0007176c15d') return

    clearInterval(autosaveInterval.current)
    autosaveInterval.current = setInterval(autosave, AUTOSAVE_SECONDS * 1000)
    return () => {
      clearInterval(autosaveInterval.current)
    }
  }, [user])

  /*
    Loading Data Effects
  */

  useTotalConsultationsCount((count, previousCount) => {
    const queueSizeChanged = isNumber(previousCount) && previousCount !== count

    if (queueSizeChanged && user) {
      dispatch(fetchRequestedConsultationsAction(accessToken, user))
    }
  }, user)

  useEffect(() => {
    if (!currentConsultation) return

    setConsultation(currentConsultation)
  }, [currentConsultation])

  useEffect(() => {
    if (isTagging || !user) return

    dispatch(fetchRequestedConsultationsAction(accessToken, user))
  }, [isTagging, user])

  useEffect(() => {
    if (!accessToken || !user) return

    dispatch(fetchVetStatusesAction(accessToken, user, role))
    dispatch(fetchSpecialistPayments(accessToken))

    if (idQueryParam && !cameFromSlackStatChannel && !cameFromClaimList) {
      dispatch(fetchConsultationAction(accessToken, idQueryParam))
    } else {
      setInQueue(true)
      dispatch(fetchRequestedConsultationsAction(accessToken, user))
    }
  }, [accessToken, user])

  useEffect(() => {
    setStartedAt(moment())
  }, [consultation])

  useEffect(() => {
    if (!inQueue) return

    if (!consultations.length) {
      setConsultation(undefined)
      return
    }

    const consultationExists = consultations.some((c) => c.id === consultation?.id)

    if (!consultation || !consultationExists) {
      let next: Consultations_consultations | undefined
      if (!consultation && idQueryParam) next = consultations.find((c) => c.id == idQueryParam)
      setConsultation(next || consultations[0])
    }
  }, [consultations, consultation, inQueue])

  useEffect(() => {
    if (!consultation || consultation.id === previouslyWorkedOnConsult?.id || !conditions) return

    const savedConditions = consultation.predictions.filter((p) => p.condition).map((p) => ({ condition_id: p.condition?.id }))
    const savedCustomFindings = consultation.predictions
      .filter((p) => !p.condition)
      .map((p) => ({ display_name: p.display_name, condition_category_id: p.condition_category?.id }))

    // set checked based on saved regions and or AI prediction
    const checked = consultation.checked_denormalized?.split(',')
    const checkedCategories = uniq(
      compact(conditions.filter((c) => checked?.includes(c.category?.display_name || '')).map((c) => c.category?.region))
    )
    if (checkedCategories.length) {
      setChecked(checkedCategories)
    } else {
      const views = uniq(
        compact(
          consultation.case.medical_images.map((m) =>
            m.view?.display_name.split(' ')[0].toLowerCase().replace('musculoskelatel', 'musculoskeletal')
          )
        )
      )
      const preselectChecked = views.includes('whole') ? ['abdomen', 'thorax', 'musculoskeletel'] : views
      if (intersection(['abdomen', 'thorax'], preselectChecked).length && !preselectChecked.includes('musculoskeletal')) {
        preselectChecked.push('musculoskeletal')
      }
      setChecked(preselectChecked)
    }

    setSelectedConditions(savedConditions)
    setNotes(consultation.receiving_vet_notes || undefined)
    setPrivateNotes(consultation.receiving_vet_private_notes || undefined)
    setCustomFindings(savedCustomFindings)
    dispatch(fetchImagesAction(flatten(consultation.case.patient.cases.map(medicalImageKeysForCase))))
    setRegionOfInterest(undefined)
    setMistakes([])

    window.scrollTo(0, 0)
  }, [consultation, conditions])

  const findMistakes = useCallback(
    debounce(async (prompt: string) => setMistakes(await queryLLM(prompt)), 3000),
    []
  )

  useEffect(() => {
    if (!notes || notes.length < 400 || !inQueue) return

    setMistakes([])
    findMistakes(`${findConsultationMistakesPrompt}:\n\n${notes}`)
  }, [notes])

  /*
    Methods
  */

  const addCustomFinding = (display_name: string, condition_category_id: number) => {
    setCustomFindings(customFindings.concat({ display_name, condition_category_id }))
    setCustomFinding('')
  }

  const completeConsultation = async (save_work_in_progress: boolean = false) => {
    if (!consultation || !user) return

    if (!save_work_in_progress && !notes) {
      dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      return
    }

    let checkedCategories: string[] = compact(
      orderBy(
        uniqBy(
          filterConditions(conditions, consultation.case.patient.species)
            .filter((c) => c.category?.order_index && checked.includes(c.category.region))
            .map((c) => c.category),
          'display_name'
        ),
        'order_index'
      ).map((c) => c?.display_name)
    )

    const minutes_to_complete = moment.duration(moment().diff(startedAt)).minutes()

    const data = {
      checked: checkedCategories,
      checked_regions: checked,
      conditions: selectedConditions.concat(...customFindings),
      id: consultation.id,
      minutes_to_complete,
      notes: notes || '',
      private_notes: privateNotes,
      receiving_vet_id: user.id,
      receiving_vet_name: user.display_name,
      completed_by_enterprise_id: getCompletedByEnterpriseId(consultation, user),
      receiving_vet_pay_amount: amountWithBonuses,
      region_of_interest: regionOfInterest,
      save_work_in_progress,
    }

    if (!save_work_in_progress) {
      const allClaimedCompleted = consultations.filter((c) => vetStatus?.my_locked_consultation_ids.includes(c.id)).length === 1
      const notification = allClaimedCompleted ? NotificationId.AllClaimedConsultationCompleted : NotificationId.ConsultationCompleted
      dispatch(setNotificationAction(notification))
      if (allClaimedCompleted) shootConfetti()
      trackHotjarEvent('completed_consultation')
    }

    return dispatch(completeConsultationAction(data))
  }

  const handleSubmit = async (save_work_in_progress: boolean = false) => {
    if (!save_work_in_progress) toggleModal()

    if (inQueue) {
      // if save_work_in_progress is true, we only save the notes and don't complete the consultation
      await completeConsultation(save_work_in_progress)
      dispatch(fetchUserAction(accessToken, user!.id, false))
      const startTagging =
        (consultation?.case.medical_images.filter(noHumanPrediction).length || 0) > 0 && isXrayCase(consultation?.case)
      if (user && (save_work_in_progress || !startTagging)) {
        dispatch(fetchRequestedConsultationsAction(accessToken, user))
        return
      }

      // create a followup
      if (followupText) {
        const followup = {
          consultation_id: consultation!.id,
          text: followupText,
          send_at: moment().add(followupDays, 'days').toISOString(),
        }
        dispatch(insertConsultationFollowupAction(accessToken, followup))
      }

      // tag medical images individually with conditions
      setSuggested(compact(selectedConditions.map((s) => s.condition_id)))
      setTaggingConsultation(consultation)
      setIsTagging(true)
    } else if (addendum) {
      insertAddendum()
    }
  }

  const insertAddendum = async () => {
    const incompleteAddendum = consultation?.addendums.find((a) => !a.text)
    if (incompleteAddendum) {
      await dispatch(updateConsultationAddendumAction(accessToken, incompleteAddendum.id, addendum!, user?.id!, addendumPrivateText))
    } else {
      const object: consultation_addendums_insert_input = {
        consultation_id: consultation?.id,
        receiving_vet_id: user?.id,
        text: addendum,
        private_text: addendumPrivateText,
      }
      await dispatch(insertConsultationAddendumAction(accessToken, object))
    }
    setAddendum(undefined)
    setAddendumPrivateText(undefined)
    dispatch(setNotificationAction(NotificationId.AddendumCreated))
    dispatch(fetchConsultationAction(accessToken, consultation?.id!))
  }

  const removeCustomFinding = (finding: predictions_normalized_insert_input) =>
    setCustomFindings(customFindings.filter((c) => c.display_name !== finding.display_name))

  const selectedCondition = (condition: Conditions_conditions) => {
    const selected = selectedConditions.find((s) => s.condition_id === condition.id)

    if (selected) {
      setSelectedConditions(without(selectedConditions, selected))
    } else {
      setSelectedConditions(selectedConditions.concat({ condition_id: condition.id }))
    }
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const updateChecked = (category: string) =>
    setChecked(checked.includes(category) ? without(checked, category) : checked.concat(category))

  /*
    Multi User Lock Methods
  */

  const isLocked = (): boolean => vetStatus?.my_locked_consultation_ids.includes(consultation?.id || 0) || false

  const handleUpdateLock = (_?: any) => {
    if (!inQueue || !user) return

    const value2 = isLocked() ? 'unlock' : 'lock'
    dispatch(updateVetStatusAction(user, 'consultation', String(consultation!.id), value2))
  }

  const handleUpdateLockThrottled = useCallback(throttle(handleUpdateLock, 1000, { trailing: false }), [
    inQueue,
    user,
    consultation,
    isLocked,
  ])

  const currentIdx = () => consultations.findIndex((c) => c.id === consultation?.id)

  const openViewer = () => {
    if (!consultation) return

    // dispatch(setMultiViewerManagerAction(new MultiViewerManager()))
    window.open(
      ohifStudyUrl(
        consultation.case.dicom_server_viewing_study_instance_uid || consultation.case.dicom_server_study_instance_uid,
        consultation.case.dicom_source
      ),
      '_blank'
    )
  }

  useEffect(() => {
    if (!multiViewerManager || !consultation) return

    const id = consultation.case.dicom_server_viewing_study_instance_uid || consultation.case.dicom_server_study_instance_uid!

    const range = 2

    const preloadIds = compact(
      consultations
        .slice(Math.max(0, currentIdx() - range - 1), Math.min(consultations.length, currentIdx() + range) + 1)
        .map((c) => c.case.dicom_server_viewing_study_instance_uid || c.case.dicom_server_study_instance_uid)
        .filter((id2) => id2 !== id)
    )

    setTimeout(() => multiViewerManager.loadStudies(id, preloadIds), 1000)
  }, [multiViewerManager, consultation])

  const completeTagging = async () => {
    setIsTagging(false)
    setTaggingConsultation(undefined)
    setSuggested([])
    if (user) dispatch(fetchRequestedConsultationsAction(accessToken, user))
  }

  if (isTagging && taggingConsultation) {
    return <ConsultTagging complete={completeTagging} consultation={taggingConsultation} suggested={suggested} />
  }

  if (!consultation) {
    return (
      <Layout>
        <MainBox defaultPadding>
          {isQuerying[QueryName.Consultations] ? <Spinner color="primary" /> : <p className="text-l">No consultations.</p>}
        </MainBox>
      </Layout>
    )
  }

  const imageUrlsForConsultation = compact(
    consultation.case.medical_images.map((m) => presignedCaseImageUrls.find((p) => m.aws_s3_url && p.includes(m.aws_s3_url)))
  )

  return (
    <Layout irxMode>
      {modal && (
        <ConfirmModal
          amount={amount}
          amountWithBonuses={amountWithBonuses}
          applyStatPlusBonus={applyStatPlusBonus}
          consultation={consultation}
          customFindings={customFindings}
          handleSubmit={() => handleSubmit(false)}
          imageUrlsForConsultation={imageUrlsForConsultation}
          missedStatPlusBonus={!applyStatPlusBonus}
          mistakes={mistakes}
          modal={modal}
          selectedConditions={selectedConditions}
          toggleModal={toggleModal}
          notes={addendum || notes}
        />
      )}

      <div style={{ gap: '50px' }} className="d-flex pt-2 px-5">
        <CaseInfo
          consultation={consultation}
          handleSubmit={handleSubmit}
          imageUrlsForConsultation={imageUrlsForConsultation}
          inQueue={inQueue}
          isLocked={isLocked()}
          lock={handleUpdateLockThrottled}
          openViewer={openViewer}
          setConsultation={setConsultation}
        />

        <FulfillConsultationForm
          addendum={addendum}
          followupText={followupText}
          setFollowupText={setFollowupText}
          followupDays={followupDays}
          setFollowupDays={setFollowupDays}
          addendumPrivateText={addendumPrivateText}
          checked={checked}
          consultation={consultation}
          customFinding={customFinding}
          customFindings={customFindings}
          displayRegionOfInterest={displayRegionOfInterest}
          handleAddCustomFinding={addCustomFinding}
          handleRemoveCustomFinding={removeCustomFinding}
          handleUpdateChecked={updateChecked}
          inQueue={inQueue}
          isLocked={isLocked()}
          lock={handleUpdateLockThrottled}
          notes={notes}
          privateNotes={privateNotes}
          regionOfInterest={regionOfInterest}
          selectedCondition={selectedCondition}
          selectedConditions={selectedConditions}
          setAddendum={setAddendum}
          setAddendumPrivateText={setAddendumPrivateText}
          setCustomFinding={setCustomFinding}
          setDisplayRegionOfInterest={setDisplayRegionOfInterest}
          setNotes={setNotes}
          setPrivateNotes={setPrivateNotes}
          setRegionOfInterest={setRegionOfInterest}
        />
      </div>

      <div
        onMouseOver={() => {
          if (submitDisabled) setSubmitTooltipOpen(true)
        }}
        onMouseLeave={() => setSubmitTooltipOpen(false)}
        style={{ right: '20px', bottom: '20px' }}
        className="pt-3 pl-3 position-fixed d-flex align-items-end"
      >
        <Tooltip fade={false} isOpen={submitTooltipOpen} target="SubmitButton">
          <div className="text-right text-s">
            Please add {inQueue ? (!notes ? 'notes' : 'checked fields') : 'addendum'} before submitting.
          </div>
        </Tooltip>

        <Button
          className={`${displayRegionOfInterest ? 'd-none' : ''}`}
          id="SubmitButton"
          color="dark-bg-blue"
          disabled={submitDisabled}
          onClick={toggleModal}
        >
          Submit
        </Button>
      </div>
    </Layout>
  )
}
